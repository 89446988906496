
:root {
    --h3: 300vh;
    --h2: 200vh;
    --h1: 100vh;
    --h025: 25vh;
    --h050: 50vh;
    --w050: 50vw;
    --h033: 33vh;
    --w033: 33vw;
    --w041: 41vw;

    --accentColor:128, 8, 255;
   --baseColor:255, 255, 255;
    --baseSize: 14px;
    --clearGrayColor:241, 243, 244;
    --whiteColor: 247, 247, 255;
    --darkColor: 3, 24, 0;
    --realWhiteColor: 255, 255, 255;
    --greenColor: 167, 201, 87;
    --blueColor: 32, 164, 243;
    --purpleColor: 128, 8, 255;
    --paleGreenColor: 243, 253, 247;
    --creamColor: 243, 240, 233;
    --creamyColor: 255, 253, 187;
    --appleColor: 196, 237, 118;
    --plumbBlue: 68, 120, 164;
    --paleYellowColor: 255, 251, 235;
    --pureWhite: 255, 255, 255;
    --translucid: #ffffff30;
    --limeColor: 212, 255, 20;
    --calendarColor: var(--blueColor);
    --base-color: var(--blueColor);
    --alternate-color: var(--whiteColor);
    --elementHeight: 50vh;


}

.body{
    overflow-x: hidden;
    position: relative;
}

.contentClub{
    font-size:.85em;
}

.search-container{
    box-sizing: border-box;
    width: 100%;
    border: none;
    padding: 1.5em 0.5em;
    position: relative;
    display: flex;
}

.searchPanel{
    font-family: NextcaddyClear, Arial, Helvetica, sans-serif;
    backdrop-filter: grayscale(1);
    width: 100%;
    min-height: 100vh;
    background-color: rgba(var(--clearGrayColor),.2);
    padding: 6em 2em 2em 2em;
    top: 50vh;
    transition: all .25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    position:relative;
    top:0;
    overflow:hidden;

}

.searchPanel:before{
    content: "";
    position: absolute;
    top: -15%;
    right: 0;
    left: 0;
    bottom: 0;
    background: white;
    transform: skewY(353deg);
    height: 65%;
}



.searchPanel:focus-within {
    background-color: rgba(var(--clearGrayColor),.8);
    /*backdrop-filter: blur(18px);*/

}

.searchPanel .search{
    position: relative;
    max-width: 100%;
    border-radius: 0.6rem;
    height: 1.63em;
    font-size: 1.2rem;
    padding: .2em 0 .2em 2.2em;
    flex: 1;
    border: .2em solid rgba(var(--base-color),.5);
    outline: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' opacity='.2' viewBox='0 0 32 32'%3E%3Cpath fill='currentColor' d='m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: .3em center;
    transition: all .3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

#sip{
    caret-color: rgba(var(--base-color), 1);
    height:3.5em;

    color:rgba(var(--plumbBlue), 1);
}

#sip:focus{
    caret-color: rgba(var(--plumbBlue), 1);

    box-shadow: 0px 0px 0px .2em rgba(var(--blueColor), 1);


    caret-color: rgba(var(--plumbBlue), 1);
    color: rgba(var(--darkColor), 1);


}

@media (min-width:1024px){
    #sip:focus {
        height: 3.8em;
        font-size: 2.1em;}


}


@media (max-width:1024px){


        .content ul{
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
        }
}










.searchPanel .search::placeholder{
    color: rgba(var(--base-color), .6);
    opacity: 1;
}

.searchPanel .search:focus:placeholder-shown {
    --alpha: .36;
    border: 5px solid rgba(var(--base-color), var(--alpha));
}

.searchPanel .search:focus {
    --alpha: 1;
    border: 5px solid rgba(var(--base-color), var(--alpha));
    border-radius:.1em;
    padding: .4em 2em;
}

.searchPanel .search:focus::placeholder{
    color: rgba(var(--clear-color), .2);
}

.searchPanel .resultsPanel details {
    color: rgba(var(--appleColor), 1);
    flex: 2;
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer;


}

@media (min-width:1024px){
    .searchPanel .resultsPanel {
        padding: 1.5em;
        display: flex;
        gap: 1em;
}

}

.searchPanel .resultsPanel details span {
    user-select: none;
}

.searchPanel .resultsPanel details summary{

       color: rgba(var(--darkColor), 1);
      font-weight: 400;
    letter-spacing: -0.0256em;

       padding: 1em;
       list-style: none;
       cursor: pointer;

}

.searchPanel .resultsPanel details summary span{
    margin:0 .24em;
}



.searchPanel .resultsPanel{
    padding: .5em;
}




.searchPanel .resultsPanel details[open]{

    background: rgba(var(--realWhiteColor), 1);

}

.searchPanel .resultsPanel details[open] summary{

    box-shadow: rgba(var(--darkColor), .1) 0px 1px;

}



.searchPanel .resultsPanel details summary:hover{
background: rgba(var(--limeColor), 1);
box-shadow: none;
outline: .2em solid white;

}

summary::selection {
    color: rgba(var(--red-color),1);
    background: transparent;
}

.searchPanel .resultsPanel details .content {
    color: rgba(var(--darkColor), .7);
    font-size: 1.2rem;

    min-height:30vh;
    max-height: 70vh;
    overflow: scroll;


}

.searchPanel .content ul li {
    display: flex;
    align-items: center;
}

.searchPanel .liCalendar {
    margin: 0 .5em;
}

.resultsPanel.liData {
    padding-top: .641em;
    color: rgba(var(--clear-color), .7);
}



.resultsPanel.liData label {

    color: rgba(var(--darkColor), 1);
      font-size: 2.5rem;
      font-weight: 400;
      letter-spacing: -0.0256em;
      font-family: NextcaddyClear, Arial, Helvetica, sans-serif;
}



li.lista{

    color: rgba(var(--darkColor), .84);
    border: 1px solid rgba(var(--base-color),.3);

}

li.lista:hover{
    color: rgba(var(--plumbColor), 1);
    border: 1px solid rgba(var(--base-color), 1);
    background: rgba(var(--limeColor), 1);
}

.searchPanel .contentClub, .contentCity{
    color: rgba(var(--base-color),.5);
    font-size: .75em;
    margin-bottom: .5em;
}

.searchPanel details .content ul li:nth-of-type(even){
    background: rgba(var(--base-color),.08);
}

.searchPanel .resultsPanel details .content ul li{
    padding: .6em .3em;

    border: 1px solid transparent;
    transition: all .3s cubic-bezier(0.000, 0.000, 0.580, 1.000);

}

.searchPanel .content ul{
    list-style-type: none;
}

.searchPanel .close{
    text-align: center;
    font-size: 1.5rem;
    justify-content: center;
    background-color: rgba(var(--base-color),.1);
    padding: 0.64em;
    border-radius: 2em;
    height: 1.2em;
    margin: 1em;
    width: 10em;
    transition: all .1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    cursor: pointer;
}

.searchPanel .close:hover{
    background-color: rgba(var(--red-color),1);
    color:white;
}

/* Calendar Container */
.searchPanel .calendarContainer {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
    color: rgba(0, 0, 0, .87);
    font-size: 1em;
    border-radius: 0 0 5px 5px;
    text-align: center;
    font-family: "Silka", Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;

}

.searchPanel .calendarContainer.multiple{
    box-shadow: rgba(var(--shadowColor), 0.4) .45rem .45rem;
}

/* Weekday */
.searchPanel .dateWeekDay {
    background: #dcddde;
    color: rgba(0,0,0,.4);
    font-size: .74em;
    padding:.12em;
    border-radius: .3em .3em 0 0;
    text-align: center;
}

/* Lower Part */
.searchPanel .dateLower {
    background-color: rgba(var(--calendarColor), 1);
    color: #fff;
    font-weight: 700;
    padding: .6em 1.32em;
    font-size: .6em;
}

/* Statistic */
.searchPanel .statistic span {
    text-align: center;
    font-size: 2em;
    text-transform: uppercase;
}

.searchPanel .statistic .month{
    display:block;
    text-align:center;
    font-size:.85rem;
}

/* Long Shadow */
.searchPanel .longShadow {
    text-shadow: 1px 1px 0px #0796e0, 2px 2px 0px #0796e0;
}

.searchPanel .enlace {
    text-decoration: none;
    margin-bottom:.5em;
}

.searchPanel .enlace, .enlace label {
    cursor: pointer;

}



@media (min-width: 768px) {
    .searchPanel .search {
        font-size: 1.6rem;
    }

    .searchPanel .resultsPanel details {
        font-size: 2.5rem;
    }

    .searchPanel .resultsPanel details .content {

        font-size: 1.641rem;

    }
}
