.data {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0.4em;
}

.ttCard {
    box-sizing: border-box;
    width: 100%;
    background: #f4f8fb;
    color: #323436;
    padding: .5em;
    border-radius: .2em;
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.ttCardContent .title {
    margin: 0;
    padding: 0 .641rem;
    font-size: 2em;
    font-weight: 600;
    color: #1f55a2;
    line-height: 1.28;
}

.ttClub {
    padding: 0 .641em;
    font-size: 1em;
    font-weight: 400;
    color: #649099;
}

.ttCard:hover {
    background: white;
    transform: scale(1.01641);
}

.ttCardContent .match {
    padding: 0 0.5rem;
    border-radius: 1rem;
    background-color: white;
}

.ttCardContent .match .data .time {
    font-size: 2rem;
    border-right: 1px solid #64909956;
    margin-right: 1rem;
    padding-right: .5rem;
    width: 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.ttCardContent .match .data .time .tee {
    font-size: 1rem;
}

.tarjetasContent {
    display: grid;
    /* gap: .5em; */
}

.players .player {
    margin: .5rem 0;
    color: #649099;
}

.match.inmediate {
    box-shadow: 0 0 0 .3px #E2121977;
    background-color: #E2121920;
    background-image: linear-gradient(to left, #E2121924 0%, #E2121901 100%);
}

.match.inmediate * {
    color: #E21219;
    border-color: #E21219 !important;
}

.match.next .data .time {
    color: #E21219;
    -webkit-animation: blink-1 1s both;
    animation: blink-1 1s both;
    animation-delay: 4s;
}

.ttCardContent.alternate .match:nth-of-type(even) {
    flex-direction: row-reverse;
}

@-webkit-keyframes blink-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes blink-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
