@font-face {
    font-family: 'silka';
    src: url('/public/fonts/silka-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'doumbar';
    src: url('/public/fonts/doumbar-plateregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.livescoringComponent .livescoringComponentContent {
    padding: .5em .33em;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.livescoringComponentContent .lista .fila.shown {
    background: lime !important;
    transform: scale(1.06);
    color: red;
}

.livescoringComponent .current {
    color: #99AACC;
    margin: 0 .35em;
    width: 1em;
    text-align: center;
}

.livescoringComponent .current.finished {
    color: #979797;
    font-weight: 400;
}

.livescoringComponent .livTitle {
    color: #1f55a2;
    font-size: 1.45em;
    font-weight: 600;
    line-height: 1.28;
    padding: 0 0.3em;
}

.livescoringComponent {
    background: #fcfcfc;
    border-radius: 0.2em;
    box-sizing: border-box;
    color: #323436;
    height: 100%;
    max-height: 100%;
    padding: 0.2em!important;
    transition: all .2s ease-out;
    width: 100%;
    font-size: 1em;
    overflow: hidden;
}

.livescoringComponent:hover{
    background-color: white;
}

.livescoringComponent.big {
    font-size: 1.1em;
}

.livescoringComponent.big .res {
    height: .1em;
    width: .1em;
}

.livescoringComponent .livTitle {
    letter-spacing: -0.03em;
}

.livescoringComponent .scClub {
    color: rgba(var(--darkColor),.35);
    font-weight:500;
    font-size: 1em;
    padding: 0.2em 0 1em 0.5em;
    letter-spacing: -0.025em;
}

.livescoringComponent .fila {
    /*padding: .4em .641em;*/
    padding:.5em .35em;
    border-radius: .2em;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.livescoringComponent .lista {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    color: #53575b;
    letter-spacing: -0.02em;
    flex-grow: 1;
    overflow: hidden;
}

.livescoringComponent .lista .queue {
    flex-grow: 1;
    overflow: scroll;
    margin-bottom: .7em;
}

.livescoringComponent .lista .podium.separatorShadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}

.livescoringComponent .res {
    align-items: center;
    align-self: end;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
    color: #fff;
    display: flex;
    font-size: 1em;
    font-weight: 600;
    height: .5em;
    justify-content: center;
    margin: .2em 0;
    padding: .9em;
    text-align: center;
    width: .5em
}

.livescoringComponent .puntuacionTotal {
    width: 4em;
}

.livescoringComponent .puntuacionTotal .res.over {
    background-color: #26408B;
}

.livescoringComponent .puntuacionTotal .res.under {
    background-color: #E21219;
}

.livescoringComponent .puntuacionTotal .res.par {
    background-color: #9AB57A;
}

.livescoringComponent .puntuacionHoy .res.especial,
.livescoringComponent .puntuacionTotal .res.especial {
    background-color: #949388;
}

.livescoringComponent .puntuacionHoy {
    width: 4em;
}

.livescoringComponent .puntuacionHoy .res.over {
    border: 1px solid #c1d1ff;
    color: #26408b;
}

.livescoringComponent .puntuacionHoy .res.empty {
    color: black;
}

.livescoringComponent .puntuacionHoy .res.under {
    border: 1px solid #FFC1C3;
    color: #E21219;
}

.livescoringComponent .puntuacionHoy .res.par {
    border: 1px solid #d8eac2;
    color: #9ab57a;
}

.livescoringComponent .fila:before {
    position: absolute;
    margin-left: -2em;
}

.livescoringComponent .podium .fila:nth-child(odd) {
    background: #eef4f7;
    border-radius: 4px;
    transition: all .3s cubic-bezier(.22, .61, .36, 1);
}

.livescoringComponent .queue .fila:nth-child(even) {
    background: #eef4f7;
    border-radius: 4px;
    transition: all .3s cubic-bezier(.22, .61, .36, 1);
}

/* .livescoringComponent:hover .fila:nth-child(3) {
    transition: all .5s cubic-bezier(.22, .61, .36, 1);
    transition-delay: 1s;
} */

.livescoringComponent .fila:nth-child(3) {
    transition: all 1.2s cubic-bezier(.22, .61, .36, 1);
}

/* .livescoringComponent:hover .fila:nth-child(odd) {
    background: #f4f8fb;
} */

.livescoringComponent .liv-head {
    display: flex;
    justify-content: end;
    margin: 0 1em .5em 2em;
}

.livescoringComponent .liv-head-item {
    font-weight: 400;
    color: #8192bf;
    margin-left: 1.1em;
    margin-right: 0.6em;
}

.livescoringComponent .liv-head-item.tj {
    width: 3em;
    text-align: center;
}

.livescoringComponent .liv-head-item.tt {
    width: 3.2em;
    text-align: center;
    margin-left: 1.2em;
}

.livescoringComponent .columnaPuntuacion {

    display: flex;
    justify-content: end;
}

.livescoringComponent .puntuacion {
    display: flex;
    font-family: "doumbar", Bahnschrift, 'DIN Alternate', 'Franklin Gothic Medium', 'Nimbus Sans Narrow', sans-serif-condensed, sans-serif;
    font-weight: 300;
    /*width: inherit;*/
}

.livescoringComponent .puntuacion > div {
    display: flex;
    margin-left: .5em;
    margin-right: .33em;
}

.livescoringComponent .puntuacion > div > div {
    margin: auto;
    margin-left: .4em;
}

.livescoringComponent .jugador {
    display: flex;
    flex-direction: column;
}

.livescoringComponent .jugador .nombre {
    text-wrap: nowrap;
    overflow: hidden;
    white-space: break-spaces;
}

.livescoringComponent .puntuacion .hoyo {
    margin: auto;
    font-weight: 600;
    color: #26408B64;
}

.livescoringComponent .zonaJugador {
    display: flex;
}

.livescoringComponent .posicion {
    margin-right: 1ch;
    color: #1f55a2;
    display: none;
    width: 1.2em;
}

.livescoringComponent.big .posicion {
    display: block;
}

.emptyLivescoring {
    background-color: white;
    padding: 1em;
    display: flex;
    border-radius: 5px;
    color: #296cbd;
}

.emptyLivescoring.big {
    background-color: #ffffff7a;
    backdrop-filter: blur(1px);
}

.emptyLivescoring h1 {
    margin: auto;
    font-size: 1.5em;
}

.emptyLivescoring.big h1 {
    font-size: 2em;
}
