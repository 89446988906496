:root {
    --darkColor: 3, 24, 0;
    --pureWhite: 255, 255, 255;
    --accentColor: 128, 8, 255;
    --baseColor: 255, 255, 255;
    --showQR: none;
}

@font-face {
    font-family: "Silka";
    font-display: fallback;
    font-weight: 300;
    font-style: normal;
    /**src: local("Silka")**/

    src: url("https://assets.codepen.io/261257/silka-medium-webfont_3.woff2") format("woff2");
}

.plazas {
    box-sizing: border-box;
    position: relative;
    color: rgba(var(--darkColor), .8);
    font-family: "Silka", Avenir, Montserrat, Corbel, "URW Gothic";
    border-radius: 5px;
    font-size: 1em;
    padding-top: .6em;
    overflow: hidden;
    box-shadow: rgb(66 21 141 / 11%) 0px 1px 4px 0px;
    animation: entrance 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    background-color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plazas:hover {
    background-color: rgba(var(--accentColor), .75);
    color: white;
}

.plazas:hover .main .restante {
    color: white;
}

.plazas:hover .main .nombreComp {
    color: white;
}

.plazas .main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.plazas .main .zonaFecha {
    padding: .3em 0;
    display: flex;
    justify-content: center;
}

.plazas .main .zonaFecha .fecha {
    font-size: 2.25em;
    font-weight: bold;
    min-width: 2.25em;
    text-align: center;
}

.plazas .zonaProgreso {
    padding: .7em .4em .5em .4em;
}

.plazas .zonaProgreso .barraProgreso {
    height: .2em;
    background-color: rgba(var(--accentColor), .14);
    border-radius: 3px;
    overflow: hidden;
    width: var(--target);
    transform-origin: 0px 0px;
}

.plazas:hover .zonaProgreso .barraProgreso {
    background-color: rgba(var(--pureWhite), .14);
}

.plazas .zonaProgreso .progreso {
    height: 100%;
    background-color: rgba(var(--accentColor), .8);
    width: var(--target);
    opacity: .4;
    animation: getThere .5s ease-in-out 1 forwards;
}

.plazas:hover .zonaProgreso .progreso {
    background-color: rgba(var(--pureWhite), .8);
}

.plazas .main h1,
.plazas .main h2 {
    margin: auto;
}

.plazas .main .nombreComp {
    font-size: 1.2em;
    font-weight: 600 !important;
    line-height: 1.28;
}

.plazas .main .nombreClub {
    color: rgba(var(--darkColor), .5);
    font-size: 1em;
    margin-top:.2em;
    letter-spacing: -0.025em;
    margin-bottom: .33em;
    font-weight: 200;
    padding-right: 1em;
}

.plazas .main .restante {
    font-size: .8rem;
    margin: auto 0;
    color: rgba(var(--accentColor), 1);
    -webkit-animation: heartbeat 1.5s ease-in-out 3 both;
    animation: heartbeat 1.5s ease-in-out 3 both;
    animation-delay: 4s;
}

.plazas .main .infoPlazas {
    font-size: .8rem;
    margin: auto 1em auto 0;
}

.plazas svg {
    height: 1em;
    width: 1em;
    opacity: .5;
    margin-right: .3em;
}

.plazas .info {
    display: flex;
    padding: .5em 0;
}

.plazas .zonaFecha .fechas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.plazas .zonaFecha .mes {
    display: flex;
    font-size: .8em;
}

.plazas .zonaTexto {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.show {
    /*cuando existe esta clase se muestra el QR*/
    --showQR: flex;
}

.plazas .zonaQr {
    display: flex;
    align-items: center;
    justify-content: center;
    display: var(--showQR);
    flex-grow: 0;
    padding: .5em 1em;
    border-left: 1px solid rgba(var(--darkColor), .1);
}

.plazas .listaEspera {
    background-color: rgba(var(--whiteColor), 1);
    display: flex;
    padding: .2em;
    padding: .25em .5em;
    position: absolute;
    top: 0;
    right: 0;
}

.plazas:hover .listaEspera {
    background-color: rgba(var(--accentColor), 1);
}

.plazas .listaEspera label {
    font-size: 10px;
    margin: auto;
}

@-webkit-keyframes getThere {
    from {
        -webkit-transform: scaleX(.8);
        transform: scaleX(.8);
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes getThere {
    from {
        transform-origin: 0 0;
        -webkit-transform: scaleX(.8);
        transform: scaleX(.8);
    }

    to {
        transform-origin: 0px 0px;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: .8;
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
